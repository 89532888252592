import { useCallback } from 'react';
import { useNavigate as useRouterNavigate, useSearchParams } from 'react-router-dom';

const useNavigate = () => {
  const [searchParams] = useSearchParams();
  const routerNavigate = useRouterNavigate();

  return useCallback((pathname: string | number, searchQuery: string | null = null) => {
    if (typeof pathname === 'number') {
      routerNavigate(pathname);
    } else if (typeof pathname === 'string') {
      routerNavigate({
        pathname,
        search: searchQuery ?? searchParams.toString(),
      });
    }
  }, [routerNavigate, searchParams]);
}

export default useNavigate;