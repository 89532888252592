export enum BatteryType {
  RACK = 'rack',
  ESS = 'ess',
  MODULE = 'module',
  CONTAINER = 'container',
}

export const typeShorten = {
  park: 'p',
  ess: 'e',
  container: 'c',
  rack: 'r',
  module: 'm',
}

export const nodetypeChildren = {
  ess: "container",
  container: "rack",
  rack: "module",
  module: null
}
