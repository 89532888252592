import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { AlertLevel, AlertType } from 'alerts/constants/alerts';
import { AlertFilter } from 'app/constants/alerts';
import useNavigate from 'app/hooks/useNavigate';

export type AlertFilterType = { type: AlertType; level: AlertLevel };
export type AlertLevelsFilter = { [K in AlertType]?: Set<AlertLevel> };

export type Pagination = { page: number; items_per_page: number };
export type Sort = { field: string, ascending: boolean };

type Context = {
  opened: boolean;
  open: (alert: AlertLevelsFilter | null, id: string | null, filter?: AlertFilter) => void;
  close: () => void;
  toggle: () => void;

  alertLevelsFilter: AlertLevelsFilter;
  setAlertLevelsFilter: Dispatch<SetStateAction<AlertLevelsFilter>>;

  selectedFilter: AlertFilter; // selects the visible alert state
  setSelectedFilter: Dispatch<SetStateAction<AlertFilter>>;

  pagination: Pagination,
  setPageNumber: (page_num: number) => void,

  sort: Sort,
  setSort: (sort: Sort) => void,
};

interface IProps {
  children: ReactNode;
}

const defaultContext = {
  opened: false,

  alertLevelsFilter: {},
  setAlertLevelsFilter: () => { },

  selectedFilter: AlertFilter.ONGOING,
  open: () => { },
  close: () => { },
  toggle: () => { },
  setSelectedFilter: () => { },
  pagination: { page: 0, items_per_page: 12 },
  setPageNumber: () => { },
  sort: {
    field: 'start',
    ascending: false,
  } as Sort,
  setSort: () => { },
};


export function alertLevelFilterToSearchParams(
  alertLevelsFilter: AlertLevelsFilter,
  searchParams: URLSearchParams = new URLSearchParams()
): URLSearchParams {
  if (Object.keys(alertLevelsFilter).length === 0) {
    Array.from(searchParams.keys()).filter(key => key.startsWith('type')).forEach(key => searchParams.delete(key));
    return searchParams;
  }
  return Object.entries(alertLevelsFilter)
    .reduce((queryParams, [alert_type, levels]) => {
      if (levels.size === 0) {
        queryParams.delete(`type${alert_type}__in`);
      } else {
        queryParams.set(`type${alert_type}__in`, Array.from(levels).join(','));
      }
      return queryParams;
    }, searchParams)
}

export function parseAlertLevelFilter(searchParams: URLSearchParams) {
  return Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith('type'))
    .reduce((filters, [key, value]) => (
      {
        ...filters,
        [Number(key.match(/\d+/))]: new Set(value.split(',').map(Number)),
      }
    ), {} as AlertLevelsFilter);
}

const StateContext = createContext<Context>(defaultContext);
StateContext.displayName = "StickyBottomAlertsBar"

function StickyBottomAlertsBarProvider({ children }: IProps) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isOpen = searchParams.get('alarmsOpen');
  const searchParamsSelectedFilter = searchParams.get('alarmsFilter');
  const searchParamsSelectedLevelFilter = parseAlertLevelFilter(searchParams);

  const [opened, open] = useState<boolean>(Boolean(isOpen));
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    items_per_page: 12
  });
  const [alertLevelsFilter, setAlertLevelsFilter] = useState<AlertLevelsFilter>(searchParamsSelectedLevelFilter);
  const [selectedFilter, setSelectedFilter] = useState<AlertFilter>(
    searchParamsSelectedFilter
      ? searchParamsSelectedFilter as AlertFilter
      : AlertFilter.ONGOING
  );

  const [sort, setSort] = useState(defaultContext.sort);

  useEffect(() => {
    setSearchParams(alertLevelFilterToSearchParams(alertLevelsFilter, searchParams));
  }, [setSearchParams, alertLevelsFilter, searchParams]);

  useEffect(() => {
    const requestedAlarmsFilter = searchParams.get('alarmsFilter') as AlertFilter;
    if (!requestedAlarmsFilter || !Object.values(AlertFilter).includes(requestedAlarmsFilter)) return;
    setSelectedFilter(requestedAlarmsFilter);
  }, [searchParams]);

  const openCallback = useCallback(
    (alert: AlertLevelsFilter | null = null, hierarchyId: string | null = null, filter: AlertFilter = AlertFilter.ONGOING) => {
      if (hierarchyId) {
        const hierarchyPath = hierarchyId.replaceAll('_', '/');
        if (location.pathname.endsWith(hierarchyPath))
          navigate(hierarchyId.replaceAll('_', '/'));
      }
      open(true);
      setSelectedFilter(filter);
      searchParams.set('alarmsOpen', 'true');
      setSearchParams(searchParams);
      if (alert) setAlertLevelsFilter(alert);
    },
    [searchParams, setSearchParams, navigate, location.pathname],
  );

  const closeCallback = useCallback(() => {
    open(false);
    setAlertLevelsFilter({});
  }, []);

  const toggleCallback = useCallback(() => {
    if (opened) {
      searchParams.delete('alarmsOpen');
      searchParams.delete('alarmsFilter');
      setSearchParams(searchParams);
      closeCallback();
    } else {
      searchParams.set('alarmsOpen', 'true');
      setSearchParams(searchParams);
      openCallback();
    }
  }, [opened, closeCallback, openCallback, setSearchParams, searchParams]);

  const setPageNumber = useCallback((page_number: number) => {
    setPagination({
      ...pagination,
      page: page_number
    })
  }, [pagination])

  const setAlertStateFilterCallback = useCallback((filter: SetStateAction<AlertFilter>) => {
    setPageNumber(0);
    setSelectedFilter(filter);
  }, [setPageNumber, setSelectedFilter]);

  const context = useMemo(() => ({
    opened,
    open: openCallback,
    close: closeCallback,
    toggle: toggleCallback,
    alertLevelsFilter,
    setAlertLevelsFilter,
    selectedFilter,
    setSelectedFilter: setAlertStateFilterCallback,
    pagination,
    setPageNumber,
    sort,
    setSort
  }), [
    opened,
    openCallback,
    closeCallback,
    toggleCallback,
    alertLevelsFilter,
    setAlertLevelsFilter,
    selectedFilter,
    setAlertStateFilterCallback,
    pagination,
    setPageNumber,
    sort,
    setSort
  ]);

  return <StateContext.Provider value={context}>{children}</StateContext.Provider>;
}

export const useStickyBottomAlerts = () => useContext(StateContext);

export default StickyBottomAlertsBarProvider;
