import { lazy, useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AlertItemProvider from 'alerts/components/AlertItemProvider';
import StickyBottomAlertsBarProvider from 'alerts/components/StickyBottomAlertsBar/provider';
import LazyComponentWrapper from 'app/components/LazyComponentWrapper';
import NotFound from 'app/components/NotFound';
import PrivateRoute from 'app/components/PrivateRoute';
import ModalProvider from 'app/components/modal';
import Logout from 'app/pages/logout';
import TimeFilterProvider from 'dashboard/components/TimeFilterProvider';
import TimelineProvider from 'dashboard/components/TimelineProvider';
import ParkProvider from 'park/components/ParkProvider';
import { ParamsItemProvider } from 'dashboard/hooks/useParamsItem';

const DashboardOverview = lazy(() => import('app/pages/dashboard/overview'));
const DashboardDetail = lazy(() => import('app/pages/dashboard/detail'));
const DashboardLayout = lazy(() => import('app/pages/dashboard/index'));

function Router() {
  const renderDashboardComponent = useCallback((component: React.ReactElement) => (
    <LazyComponentWrapper>{component}</LazyComponentWrapper>
  ), []);

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={'/dashboard/overview'} replace />} />
      <Route path={'/logout'} element={<Logout />} />
      <Route
        path={'/dashboard'}
        element={
          <PrivateRoute>
            <LazyComponentWrapper>
              <ParkProvider>
                <ParamsItemProvider>
                  <ToastContainer />
                  <TimeFilterProvider>
                    <TimelineProvider>
                      <StickyBottomAlertsBarProvider>
                        <AlertItemProvider>
                          <ModalProvider>
                            <DashboardLayout />
                          </ModalProvider>
                        </AlertItemProvider>
                      </StickyBottomAlertsBarProvider>
                    </TimelineProvider>
                  </TimeFilterProvider>
                </ParamsItemProvider>
              </ParkProvider>
            </LazyComponentWrapper>
          </PrivateRoute>
        }>
        <Route path={'overview'} element={renderDashboardComponent(<DashboardOverview />)} />
        <Route path={':item1'} element={renderDashboardComponent(<DashboardDetail />)} />
        <Route path={':item1/:item2'} element={renderDashboardComponent(<DashboardDetail />)} />
        <Route path={':item1/:item2/:item3'} element={renderDashboardComponent(<DashboardDetail />)} />
        <Route path={':item1/:item2/:item3/:item4'} element={renderDashboardComponent(<DashboardDetail />)} />
      </Route>
      <Route path={'*'} element={<NotFound />} />
    </Routes>
  );
}

export default Router;
